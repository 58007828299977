import React, { Component , useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import ReactHtmlParser from 'html-react-parser';
import "react-vertical-timeline-component/style.min.css";
import Badge from "react-bootstrap/Badge";

const Collapsible = (props) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <h6>
        
        {showMore ? ReactHtmlParser(String(props.text)) : `${props.text.substring(0, 0)}`}
        <div style={{display:"flex", justifyContent:"center"}}>
          <button className="button-readmore" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show less" : "Read More ↓"}
        </button>
        </div>
        
      </h6>
    </div>
  );
}

class Experience extends Component {
  render() {
    if (this.props.resumeExperience && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.experience;
      var work = this.props.resumeExperience.map(function (work, i) {
        const technologies = work.technologies;
        const mainTechnologies = work.mainTech;
        const companyIcon = work.iconPath;

        var mainTech = mainTechnologies.map((technology, i) => {
          return (
            <Badge pill className="main-badge mr-2 mb-2" key={i}>
              {technology}
            </Badge>
          );
        });
        var tech = technologies.map((technology, i) => {
          return (
            <Badge pill className="experience-badge mr-2 mb-2" key={i}>
              {technology}
            </Badge>
          );
        });
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date={work.years}
            iconStyle={{
              background: "#fff",
              textAlign: "center",
            }}
            icon={<img
                    height="100%"
                    width="100%"
                    src={companyIcon}
                    alt="myLogo" 
                  />}
            key={i}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              {mainTech}
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              {work.company}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              {work.title}
            </h4>
            <div style={{ textAlign: "left", marginTop: "15px" }}>{tech}</div>
            <h5
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              <div className="modal-description">
              {ReactHtmlParser(String(work.intro))}
            </div>
            </h5>
            <Collapsible text={work.description}></Collapsible>
          </VerticalTimelineElement>
        );
      });
    }

    return (
      <section id="resume" className="pb-5">
        <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title" style={{ color: "black" }}>
              <span className="text-black" style={{ textAlign: "center" }}>
                {sectionName}
              </span>
            </h1>
          </div>
        </div>
        <div className="col-md-8 mx-auto">
          <VerticalTimeline>
            {work}
            <VerticalTimelineElement
              iconStyle={{
                background: "#AE944F",
                color: "#fff",
                textAlign: "center",
              }}
            />
          </VerticalTimeline>
        </div>
      </section>
    );
  }
}

export default Experience;

