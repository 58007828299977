import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import '@fortawesome/fontawesome-free/js/all.js';
import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "../scss/light-slider.scss";
import AwesomeSliderStyles2 from "../scss/dark-slider.scss";
import ReactHtmlParser from 'html-react-parser';
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";
class ProjectDetailsModal extends Component {
  render() {
    if (this.props.data) {
      const technologies = this.props.data.technologies;
      const images = this.props.data.images;
      const urls = this.props.data.urls;
      var title = this.props.data.title;
      var description = this.props.data.description;
      if (this.props.data.technologies) {
        var tech = technologies.map((icons, i) => {
          return (
            <li className="list-inline-item mx-3" key={i}>
              <span>
                <div className="text-center">
                  <i className={icons.class} style={{ fontSize: "300%" }}>
                    <p className="text-center" style={{ fontSize: "30%" }}>
                      {icons.name}
                    </p>
                  </i>
                </div>
              </span>
            </li>
          );
        });
        if (this.props.data.images) {
          var img = images.map((image, i) => {
            return (
              <div className="project-container" key={i}>
                <img className="project-image"  alt="here" src={image.path}></img>
                <p className="project-caption">{image.caption}</p>
              </div>
            )
          });
        }
        if (this.props.data.urls) {
          var urlList = urls.map((url, i) => {
            return (
              <h4 key={i} class="project-link">
                <i className={url.iconClass} style={{ fontSize: "220%", padding: "0px 10px 0px 0px" }}></i>
                <a key={i} href={url.link} style={{padding: "5px 0px 5px 0px" }}>
                  {url.urlLabel}
                  <i
                        className="fas fa-external-link-alt"
                        style={{ marginLeft: "10px" }}
                  ></i>
                </a>
              </h4>
            )
          });
        }
      }
    }
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-inside"
      >
        <span onClick={this.props.onHide} className="modal-close">
          <i className="fas fa-times fa-3x close-icon"></i>
        </span>
        <div className="col-md-12">
          <div className="col-md-10 mx-auto" style={{ paddingBottom: "50px" }}>
            <div className="slider-tab">
              <span
                className="iconify slider-iconfiy"
                data-icon="emojione:red-circle"
                data-inline="false"
                style={{ marginLeft: "5px" }}
              ></span>{" "}
              &nbsp;{" "}
              <span
                className="iconify slider-iconfiy"
                data-icon="twemoji:yellow-circle"
                data-inline="false"
              ></span>{" "}
              &nbsp;{" "}
              <span
                className="iconify slider-iconfiy"
                data-icon="twemoji:green-circle"
                data-inline="false"
              ></span>
            </div>
            <AwesomeSlider
              cssModule={[AwesomeSliderStyles, AwesomeSliderStyles2]}
              play={true}
              cancelOnInteraction={false}
              interval={6000}
              className="slider-image"
            >
              {img}
            </AwesomeSlider>
          </div>
          <div className="col-md-10 mx-auto">
            <h3 style={{ padding: "5px 5px 5px 5px", fontSize: "25px", textDecoration: "underLine"}}>
              {title}
            </h3>
            {urlList}
            <div>
              {ReactHtmlParser(String(description))}
            </div>
            <div className="col-md-12 text-center">
              <ul className="list-inline mx-auto" style={{fontSize: "20px"}}>{tech}</ul>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProjectDetailsModal;
